/* eslint-disable no-irregular-whitespace */
// import { Box, Typography } from "@mui/material";
import React from "react";
// import FacebookIcon from '../../../images/footer/social-icons/Vector.png'
// import DiscordIcon from "../../../images/footer/social-icons/Discord.svg";
// import InstagramIcon from "../../../images/footer/social-icons/Instagram.svg";
// import TikTokIcon from '../../../images/footer/social-icons/TikTok.png'
// import TwitterIcon from "../../../images/footer/social-icons/Twitter.svg";
// import FlowImage from '../../../images/footer/social-icons/Flow.png'
// import NFLImage from "../../../images/footer/social-icons/NFL.png";
// import FlowLogo_Green_White from "../../../images/footer/social-icons/FlowLogo_Green_White.svg";

//import footer icons
// import BuffaloBills from "../../../images/footer/icons/Buffalo Bills.svg";
// import ArizonaCardinals from "../../../images/footer/icons/Arizona Cardinals.svg";
// import AtlantaFalcons from "../../../images/footer/icons/Atlanta Falcons.svg";
// import BaltimoreRavens from "../../../images/footer/icons/Baltimore Ravens.svg";
// import CarolinaPanthers from "../../../images/footer/icons/Carolina Panthers.svg";
// import ChicagoBear from "../../../images/footer/icons/Chicago Bears.svg";
// import CincinnatiBengals from "../../../images/footer/icons/Cincinnati Bengals.svg";
// import ClevelandBrowns from "../../../images/footer/icons/Cleveland Browns.svg";
// import DallasCowboys from "../../../images/footer/icons/Dallas Cowboys.svg";
// import DenverBroncos from "../../../images/footer/icons/Denver Broncos.svg";
// import DetroitLions from "../../../images/footer/icons/Detroit Lions.svg";
// import GreenBayPackers from "../../../images/footer/icons/Green Bay Packers.svg";
// import HoustonTexans from "../../../images/footer/icons/Houston Texans.svg";
// import IndianapolisColts from "../../../images/footer/icons/Indianapolis Colts.svg";
// import JacksonvilleJaguars from "../../../images/footer/icons/Jacksonville Jaguars.svg";
// import KansasCityChiefs from "../../../images/footer/icons/Kansas City Chiefs.svg";
// import LasVegasRaiders from "../../../images/footer/icons/Las Vegas Raiders.svg";
// import LosAngelesChargers from "../../../images/footer/icons/Los Angeles Chargers.svg";
// import LosAngelesRams from "../../../images/footer/icons/Los Angeles Rams.svg";
// import MiamiDolphins from "../../../images/footer/icons/Miami Dolphins.svg";
// import MinnesotaVikings from "../../../images/footer/icons/Minnesota Vikings.svg";
// import NewEnglandPatriots from "../../../images/footer/icons/New England Patriots.svg";
// import NewOrleansSaints from "../../../images/footer/icons/New Orleans Saints.svg";
// import NewYorkGiants from "../../../images/footer/icons/New York Giants.svg";
// import NewYorkJets from "../../../images/footer/icons/New York Jets.svg";
// import PhiladelphiaEagles from "../../../images/footer/icons/Philadelphia Eagles.svg";
// import PittsburghSteelers from "../../../images/footer/icons/Pittsburgh Steelers.svg";
// import SanFrancisco49ers from "../../../images/footer/icons/San Francisco 49ers.svg";
// import SeattleSeahawks from "../../../images/footer/icons/Seattle Seahawks.svg";
// import TampaBayBuccaneers from "../../../images/footer/icons/Tampa Bay Buccaneers.svg";
// import TennesseeTitans from "../../../images/footer/icons/Tennessee Titans.svg";
// import WashingtonCommanders from "../../../images/footer/icons/Washington Commanders.svg";
import LogoImg from '../../../images/footer/icons/footer-logo.png';
import Facebook from '../../../images/footer/icons/Facebook.svg';
import Twiter from '../../../images/footer/icons/Twitter.svg';
import Youtube from '../../../images/footer/icons/YouTube.svg';
import Instagram from '../../../images/footer/icons/Instagram.svg';
import Discord from '../../../images/footer/icons/Discord.svg';


// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Link } from "react-router-dom";
import styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className={styles.footer_parent_box}>
          <div className={styles.logo_section}>
            <img className={styles.logo} src={LogoImg} />
            <div className={styles.logo_box}>
              <a href='https://www.facebook.com/BudLight' target="_blank" rel="noreferrer"><img src={Facebook} /></a>
              <a href='https://twitter.com/budlight' target="_blank" rel="noreferrer"><img className={styles.twiter_box} src={Twiter} /></a>
              <a href='https://www.youtube.com/user/officialbudlight' target="_blank" rel="noreferrer"><img src={Youtube} /></a>
              <a href='https://www.instagram.com/budlight/' target="_blank" rel="noreferrer"><img src={Instagram} /></a>
              <a href='https://discord.com/invite/budlight' target="_blank" rel="noreferrer"><img src={Discord} /></a>
            </div>
          </div>
          <div className={styles.footer_menu_box}>
            <div>
              <h6>Anheuser-Busch</h6>
              <ul>
                <li><a target="_blank" href={"https://www.anheuser-busch.com/"} rel="noreferrer">About Anheuser-Busch</a></li>
                <li><a target="_blank" href={"https://www.anheuser-busch.com/careers/"} rel="noreferrer">Careers</a></li>
                <li><a target="_blank" href={"https://www.anheuser-busch.com/community/responsible-drinking/"} rel="noreferrer">Social Responsibility</a></li>
                <li><a target="_blank" href={"https://www.anheuser-busch.com/newsroom/"} rel="noreferrer">Press</a></li>
              </ul>
            </div>
            <div>
              <h6>Support</h6>
              <ul>
                <li><a target="_blank" href={"https://www.budlight.com/contact-us"} rel="noreferrer">Contact Us</a></li>
                <li><a target="_blank" href={"https://tapintoyourbeer.com"} rel="noreferrer">Consumer Information</a></li>
                <li><a target="_blank" href={"https://www.budlight.com/accessibility"} rel="noreferrer">Accessibility Statement</a></li>
              </ul>
            </div>
            <div>
              <h6>Policies and Legal</h6>
              <ul>
                <li><a  target='_blank' href='https://www.budlight.com/terms-and-conditions' rel="noreferrer">Terms & Conditions</a></li>
                <li><a  target='_blank'  href="https://www.budlight.com/privacy-policy/" rel="noreferrer">Privacy  Policy</a></li>
                <li><a target='_blank' href="https://www.budlight.com/california-residents-privacy-rights" rel="noreferrer">Do Not Sell My Personal <br></br>Information</a></li>
                <li><a target='_blank' href="https://www.anheuser-busch.com/contact-us/" rel="noreferrer">Limit The Use Of My Sensitive<br></br>Personal Information</a></li>
                {/* <button>join newsletter</button> */}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.line_box} />
        <div className={styles.enjoy_box}>
          <p>ENJOY RESPONSIBLY. DO NOT SHARE THIS CONTENT WITH THOSE  UNDER 21. © 2023 ANHEUSER-BUSCH, BUD LIGHT® BEER, ST. LOUIS, MO</p>
          {/* <ul>
            <li><a  target='_blank' href='https://www.budlight.com/accessibility' rel="noreferrer">Terms <br></br> & Conditions</a></li>
            <li><a  target='_blank'  href="https://www.budlight.com/privacy-policy/" rel="noreferrer">Privacy <br></br> Policy</a></li>
            <li><a target='_blank' href="https://www.budlight.com/california-residents-privacy-rights" rel="noreferrer">Do Not Sell My Personal <br></br>Information</a></li>
            <li><a target='_blank' href="https://www.tapintoyourbeer.com/" rel="noreferrer">Limit The Use Of My Sensitive<br></br>Personal Information</a></li>
          </ul> */}
        </div>
        <div className={styles.enjoy_box}>
          <p>ANHEUSER-BUSCH IS COMMITTED TO IMPROVING THE ACCESSIBILITY OF OUR WEBSITES FOR ALL USERS,INCLUDING THOSE WITH DISABILITIES. <a target="_blank" href={"https://www.budlight.com/accessibility"} rel="noreferrer">READ OUR ACCESSIBILITY STATEMENT HERE.</a></p>
        </div>
        <div className={styles.enjoy_box}>
          <p>BUD LIGHT® NFL® EASY PICKS. No Purchase Necessary. Open to US residents 21+. Begins 9/1/23 & ends 1/7/24. Multiple entry periods. See Official Rules at budlight.com/easypicksNFLrules for entry deadlines, prizes & details. Msg & data rates may apply. Void where prohibited.</p>
        </div>
      </div>
    </footer>
    // <Box
    //   justifyContent="space-evenly"
    //   display="block"
    //   sx={{
    //     backgroundColor: "primary.main",
    //     alignSelf: "center",
    //     color: "#ffffffbf"
    //   }}
    //   id="footerContainer"
    //   className={styles["footerContainer"]}
    // >
    //   <Box display="block" m="auto" textAlign={"center"}>
    //     <div className={styles["footer-links"]}></div>
    //   </Box>
    //   <div
    //     className="container pb-4"
    //     display="block"
    //     style={{ textAlign: "center" }}
    //   >
    //     <div className="row" style={{ padding: "16px" }}>
    //       <div className="col-5 text-start mx-0 responsive-show">
    //         <Box
    //           display="block"
    //           m="auto"
    //           className="p-0 "
    //           textAlign={"left"}
    //           style={{ width: "250%" }}
    //         >
    //           <div className={styles["responsive-footer-link"]}>
    //             <div className="mx-0 ">
    //               <a
    //                 href="https://www.ab-inbev.com/nftterms/"
    //                 target="_blank"
    //                 rel="noreferrer"
    //                 className={`${styles["other-link"]} ${styles["footer-link"]} ${styles["link"]}`}
    //               >
    //                 Terms and Conditions
    //               </a>
    //             </div>

    //             {/*}<div className='mx-0'>
    //               <a href="https://www.budlight.com/accessibility/" target='_blank' className='other-link footer-link link' >Terms and Conditions </a>
    //             </div>
    //             */}
    //           </div>
    //         </Box>
    //         <Box
    //           display="block"
    //           m="auto"
    //           textAlign={"left"}
    //           style={{ width: "250%" }}
    //         >
    //           <div className={styles["responsive-footer-link"]}>
    //             <div className="mx-0">
    //               <a
    //                 href="https://www.budlight.com/contact-us/"
    //                 target="_blank"
    //                 rel="noreferrer"
    //                 className={`${styles["other-link"]} ${styles["footer-link"]} ${styles["link"]}`}
    //               >
    //                 Contact Us
    //               </a>
    //               <br />
    //               <a
    //                 href="https://www.budlight.com/privacy-policy/"
    //                 target="_blank"
    //                 rel="noreferrer"
    //                 className={`${styles["other-link"]} ${styles["footer-link"]} ${styles["link"]}`}
    //               >
    //                 Privacy Policy
    //               </a>
    //             </div>
    //           </div>
    //         </Box>
    //         <Box
    //           display="block"
    //           m="auto"
    //           textAlign={"left"}
    //           style={{ width: "250%" }}
    //         >
    //           <div className={styles["responsive-footer-link"]}>
    //             <div className="mx-0">
    //               <a
    //                 href="https://www.budlight.com/california-residents-privacy-rights/"
    //                 target="_blank"
    //                 rel="noreferrer"
    //                 className={`${styles["other-link"]} ${styles["footer-link"]} ${styles["link"]}`}
    //               >
    //                 Do not sell my personal information
    //               </a>
    //               <br />
    //               <a
    //                 href="https://www.tapintoyourbeer.com/"
    //                 target="_blank"
    //                 rel="noreferrer"
    //                 className={`${styles["other-link"]} ${styles["footer-link"]} ${styles["link"]}`}
    //               >
    //                 Consumer information
    //               </a>
    //             </div>
    //             {/*}<div className='mx-0'>
    //               <a href="https://www.tapintoyourbeer.com/" target='_blank' className='other-link footer-link link' >Consumer information</a>
    //             </div>*/}
    //           </div>
    //         </Box>
    //       </div>
    //       <div className="col-7 responsive-show">
    //         <Box display="block" m="auto" textAlign={"right"}>
    //           <a
    //             className={styles["footer-link"]}
    //             target={"_blank"}
    //             rel="noreferrer"
    //             display="inline"
    //             href="https://twitter.com/budlight"
    //           >
    //             <img
    //               src={TwitterIcon}
    //               alt="Twitter"
    //               className="mx-1"
    //               style={{ height: "18px" }}
    //             />
    //           </a>
    //           <a
    //             className={styles["footer-link"]}
    //             target={"_blank"}
    //             rel="noreferrer"
    //             display="inline"
    //             href="https://www.instagram.com/budlight"
    //           >
    //             <img
    //               src={InstagramIcon}
    //               alt="Instagram"
    //               className="mx-1"
    //               style={{ height: "18px" }}
    //             />
    //           </a>
    //           <a
    //             className={styles["footer-link"] + " me-0"}
    //             target={"_blank"}
    //             rel="noreferrer"
    //             display="inline"
    //             href="https://discord.gg/budlight"
    //           >
    //             <img
    //               src={DiscordIcon}
    //               alt="Discord"
    //               className="ms-1 me-0"
    //               style={{ height: "22px" }}
    //             />
    //           </a>
    //         </Box>
    //       </div>
    //     </div>
    //     <div className="responsive-hide container-fluid">
    //       <div className="row">
    //         <ul
    //           className={"col-9 p-0 text-start " + styles["nav-left"]}
    //         >
    //           <li style={{ float: "left" }}>
    //             <a
    //               href="https://www.ab-inbev.com/nftterms/"
    //               target="_blank"
    //               rel="noreferrer"
    //               className={`${styles["other-link"]} ${styles["footer-link"]} px-2`}
    //             >
    //               Terms and Conditions
    //             </a>
    //           </li>
    //           <li style={{ float: "left", paddingLeft: "10px" }}>
    //             <a
    //               href="https://www.budlight.com/contact-us/"
    //               target="_blank"
    //               rel="noreferrer"
    //               className={`${styles["other-link"]} ${styles["footer-link"]}`}
    //             >
    //               Contact Us
    //             </a>
    //           </li>
    //           <li style={{ float: "left", paddingLeft: "10px" }}>
    //             <a
    //               href="https://www.budlight.com/privacy-policy/"
    //               target="_blank"
    //               rel="noreferrer"
    //               className={`${styles["other-link"]} ${styles["footer-link"]} px-2`}
    //             >
    //               Privacy Policy
    //             </a>
    //           </li>
    //           <li style={{ float: "left", paddingLeft: "10px" }}>
    //             <a
    //               href="https://www.budlight.com/california-residents-privacy-rights/"
    //               target="_blank"
    //               rel="noreferrer"
    //               className={`${styles["other-link"]} ${styles["footer-link"]}`}
    //               style={{ paddingRight: "10px" }}
    //             >
    //               Do not sell my personal information
    //             </a>
    //           </li>
    //           <li style={{ float: "left", paddingLeft: "10px" }}>
    //             <a
    //               href="https://www.tapintoyourbeer.com/"
    //               target="_blank"
    //               rel="noreferrer"
    //               className={`${styles["other-link"]} ${styles["footer-link"]}`}
    //             >
    //               Consumer information
    //             </a>
    //           </li>
    //         </ul>
    //         <div className="col-3 p-0">
    //           <Box display="block" m="auto" textAlign={"right"}>
    //             <a
    //               className={styles["footer-link"]}
    //               target={"_blank"}
    //               rel="noreferrer"
    //               display="inline"
    //               href="https://twitter.com/budlight"
    //             >
    //               <img src={TwitterIcon} alt="Twitter" />
    //             </a>
    //             <a
    //               className={styles["footer-link"]}
    //               target={"_blank"}
    //               rel="noreferrer"
    //               display="inline"
    //               href="https://www.instagram.com/budlight"
    //             >
    //               <img src={InstagramIcon} alt="Instagram" />
    //             </a>
    //             <a
    //               className={styles["footer-link"] + " me-0"}
    //               target={"_blank"}
    //               rel="noreferrer"
    //               display="inline"
    //               href="https://discord.gg/budlight"
    //             >
    //               <img
    //                 src={DiscordIcon}
    //                 alt="Discord"
    //                 style={{ width: "30px", height: "30px" }}
    //               />
    //             </a>
    //           </Box>
    //         </div>
    //       </div>
    //     </div>

    //     <hr className="responsive-m" />
    //     <div className={styles["desktop-footer-responsive"]}>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={BuffaloBills}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={MiamiDolphins}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewEnglandPatriots}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewYorkJets}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={BaltimoreRavens}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ClevelandBrowns}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={CincinnatiBengals}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={PittsburghSteelers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={HoustonTexans}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={IndianapolisColts}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={JacksonvilleJaguars}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={TennesseeTitans}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DenverBroncos}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={KansasCityChiefs}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LasVegasRaiders}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LosAngelesChargers}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex" style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DallasCowboys}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewYorkGiants}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={PhiladelphiaEagles}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={WashingtonCommanders}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ChicagoBear}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DetroitLions}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={GreenBayPackers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={MinnesotaVikings}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={AtlantaFalcons}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewOrleansSaints}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={CarolinaPanthers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={TampaBayBuccaneers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ArizonaCardinals}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LosAngelesRams}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={SanFrancisco49ers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={SeattleSeahawks}
    //           alt="png"
    //         />
    //       </div>
    //     </div>
    //     <div className={styles["pad-footer-responsive"]}>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={BuffaloBills}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={MiamiDolphins}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewEnglandPatriots}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewYorkJets}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={BaltimoreRavens}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ClevelandBrowns}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={CincinnatiBengals}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={PittsburghSteelers}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={HoustonTexans}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={IndianapolisColts}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={JacksonvilleJaguars}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={TennesseeTitans}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DenverBroncos}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={KansasCityChiefs}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LasVegasRaiders}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LosAngelesChargers}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex" style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DallasCowboys}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewYorkGiants}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={PhiladelphiaEagles}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={WashingtonCommanders}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ChicagoBear}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DetroitLions}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={GreenBayPackers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={MinnesotaVikings}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={AtlantaFalcons}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewOrleansSaints}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={CarolinaPanthers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={TampaBayBuccaneers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ArizonaCardinals}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LosAngelesRams}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={SanFrancisco49ers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={SeattleSeahawks}
    //           alt="png"
    //         />
    //       </div>
    //     </div>
    //     <div className={styles["iphone-footer-responsive"]}>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={BuffaloBills}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={MiamiDolphins}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewEnglandPatriots}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewYorkJets}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={BaltimoreRavens}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ClevelandBrowns}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={CincinnatiBengals}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={PittsburghSteelers}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={HoustonTexans}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={IndianapolisColts}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={JacksonvilleJaguars}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={TennesseeTitans}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DenverBroncos}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={KansasCityChiefs}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LasVegasRaiders}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LosAngelesChargers}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex" style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DallasCowboys}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewYorkGiants}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={PhiladelphiaEagles}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={WashingtonCommanders}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ChicagoBear}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={DetroitLions}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={GreenBayPackers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={MinnesotaVikings}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={AtlantaFalcons}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={NewOrleansSaints}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={CarolinaPanthers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={TampaBayBuccaneers}
    //           alt="png"
    //         />
    //       </div>
    //       <div className="d-flex " style={{ justifyContent: "space-between" }}>
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={ArizonaCardinals}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={LosAngelesRams}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={SanFrancisco49ers}
    //           alt="png"
    //         />
    //         <LazyLoadImage
    //           style={{ minHeight: "60px" }}
    //           src={SeattleSeahawks}
    //           alt="png"
    //         />
    //       </div>
    //     </div>
    //     <div className="row col-lg-9 p-16">
    //       <Typography
    //         className={styles["footer-copyright"] + " text-start py-0 my-3"}
    //         style={{ fontSize: "80%" }}
    //       >
    //         Always Enjoy Responsibly. © 2023 Anheuser-Busch InBev BUD LIGHT ®
    //         BEER, St. Louis, MO. Do not share this content with minors.
    //       </Typography>
    //       <Typography
    //         className={styles["footer-copyright"] + " text-start py-0 "}
    //         style={{ fontSize: "80%" }}
    //       >
    //         ANHEUSER-BUSCH IS COMMITTED TO IMPROVING THE ACCESSIBILITY OF OUR
    //         WEBSITES FOR ALL USERS, INCLUDING THOSE WITH DISABILITIES. IF YOU
    //         EXPERIENCE ISSUES OR DIFFICULTIES ACCESSING ANY INFORMATION ON OUR
    //         SITE AS A RESULT OF A DISABILITY, WE WANT TO HEAR FROM YOU. PLEASE
    //         CONTACT US AT CRG@ANHEUSER-BUSCH.COM WITH YOUR CONTACT INFORMATION,
    //         THE WEB ADDRESS WHERE YOU EXPERIENCED THE ISSUE, AND A BRIEF
    //         DESCRIPTION OF THE PROBLEM. THIS EMAIL ADDRESS IS INTENDED FOR
    //         ACCESSIBILITY-RELATED INQUIRIES ONLY. IF YOU HAVE AN UNRELATED
    //         COMPLAINT OR FEEDBACK, PLEASE VISIT OUR SUPPORT PAGE.
    //       </Typography>
    //       <Typography
    //         className={styles["footer-copyright"] + " text-start py-0 my-3"}
    //         style={{ fontSize: "80%" }}
    //       >
    //         ©2023 NFL Properties, LLC. All Rights Reserved. <br />
    //         <br />
    //         Officially Licensed Partner of the National Football League Players
    //         Association © [2023]
    //       </Typography>

    //       <Typography
    //         className={styles["footer-copyright"] + " text-start pt-0"}
    //         style={{ fontSize: "80%" }}
    //       >
    //        BUD LIGHT® x NFL ULTIMATE FANDOM PICK ‘EM 2022-23 PLAYOFF EDITION PROMOTION. No Purchase Necessary. Open to US residents 21+. Begins 1/7/23 & ends 2/12/23. Multiple entry periods. Visit https://www.budlight.com/ultimatefandomrules/playoff2023 for entry deadlines & Official Rules. Msg & data rates may apply. Void where prohibited.
    //         <br />
    //         <br />
    //       </Typography>
    //       <Typography
    //         className={styles["footer-copyright"] + " text-start pt-0"}
    //         style={{
    //           display: "flex",
    //           flexDirection: "row",
    //           columnGap: "10px",
    //           alignItems: "center",
    //         }}
    //       >
    //         <LazyLoadImage
    //           src={NFLImage}
    //           alt="NFL"
    //           style={{ width: "15%", paddingTop: "0px" }}
    //         />
    //         <br />
    //         <br />
    //         <LazyLoadImage
    //           src={FlowLogo_Green_White}
    //           alt="Flow"
    //           style={{ width: "20%", height: "50%" }}
    //         />
    //         <br />
    //       </Typography>
    //     </div>
    //   </div>
  );
};

export default Footer;
