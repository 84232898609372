import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import BudLightLogo from "../../images/header/nfl-logo.png";
import { refreshToken } from "../../Utils/https";
// import { VectorLogowhite } from "assets/Icons";
// import dark from "theme/palette/dark";

// Takes a long hash string and truncates it.
// function truncateHash(hash, length = 38) {
//   return hash.replace(hash.substring(6, length), "...");
// }

const Header = () => {
  // const [formdata, setFormdata] = useState({
  //   email: "",
  // });
  const [loginToken, setLoginToken] = useState(false);
  const location = useLocation();

  // const handleformdata = (e) => {
  //   const { name, value } = e.target;

  //   let updatedValue = value;

  //   if (name === "email" && typeof value === "string") {
  //     updatedValue = value.replace(/\s/g, ""); // Remove spaces from email
  //   }

  //   if (name === "email" && updatedValue.includes(" ")) {
  //     // Reject input with spaces
  //     return;
  //   }

  //   setFormdata({
  //     ...formdata,
  //     [name]: updatedValue,
  //   });
  // };

  // const navigate = useNavigate();

  const topMove = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    document.getElementById("appViewLayout") &&
      (document.getElementById("appViewLayout").scrollTop = 0);
    document.documentElement.scrollTop = 0;

    return setTimeout(() => {
      document.getElementById("navbarSupportedContent").className =
        "navbar-collapse mobile-nav collapse";
    }, [100]);
  };

  useEffect(() => {
    (async () => {
      const token = await refreshToken();
      if (token) {
        setLoginToken(true);
      }
    })();
  }, [location]);

  return (
    <>
      <div className={styles["top-header"]}>
        <nav
          className={styles["nav-2"] + " navbar navbar-expand-xl"}
          style={{ background: "var(--mainColor)" }}
        >
          <div
            className="container mt-2 "
            style={{ justifyContent: "space-between" }}
          >
            <div className="navbar-brand-logo-section">
              <Link
                className="navbar-brand logo-header"
                to="/"
                onClick={topMove}
              >
                <img src={BudLightLogo} alt="" />
              </Link>
            </div>
            {/* <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav mb-2 mb-lg-0"
                style={{ overflowY: "auto", maxHeight: "calc(100vh - 85px)" }}
              >
                <li className="nav-item mx-1">
                  <Link
                    to="/pickem-game"
                    className="nav-link"
                    onClick={topMove}
                  >
                    PLAY NOW
                  </Link>
                </li>
                <li className="nav-item mx-1">
                  <Link to="/prizes" className="nav-link" onClick={topMove}>
                    PRIZES
                  </Link>
                </li>
                <li className="nav-item mx-1">
                  <Link
                    to="/leaderboard"
                    className="nav-link"
                    onClick={topMove}
                  >
                    LEADERBOARD
                  </Link>
                </li>
                {loginToken ? (
                  <li className="nav-item mx-1">
                    <Link to="/profile" className="nav-link" onClick={topMove}>
                      PROFILE
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div> */}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
