import { Auth } from "aws-amplify"

export const refreshToken = () => {
  return new Promise((resolve) => {
    Auth.currentSession()
      .then((data) => {
        const tokenObj = data.getIdToken()
        resolve(tokenObj.getJwtToken())
      })
      .catch(() => {
        resolve('')
      })
  })
}

export const requestHeader = () => {
    return new Promise(async (resolve) => {
        try {
            const token = await refreshToken();
    
            const headers = {
              'Content-Type': 'application/json',
              Authorization: token || '',
            }

            resolve(headers);
        } catch (err) {
            const headers = {
              'Content-Type': 'application/json',
              Authorization: '',
            }
            resolve(headers);
        }
    })

}