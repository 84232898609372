import AppViewLayout from "../layout/AppViewLayout";
// import Landing from "../page/Landing";
// import LeaderBoard from "page/LeaderBoard";
// import PickEmGame from 'page/PickGame'
import { Navigate } from "react-router-dom";
// import ForgotPassword from "../page/ForgotPassword"
// import ResetPassword from "../page/ResetPassword"
// import EmailVerification from "components/common/EmailVerification";
// import Prize from "../page/Prize"
// import Share from "../page/Share";
// import Profile from "../page/Profile";
import Wrapped from '../page/Wrapped'


const routesConfig = [
  {
    // path: "/",
    element: <AppViewLayout />,
    children: [
      // { path: "/", element: <Landing /> },
      // { path: "/pickem-game", element: <PickEmGame /> },
      // { path: "/forgot-password", element: <ForgotPassword /> },
      // { path: "/reset-password", element: <ResetPassword /> },
      // { path: "/prizes", element: <Prize /> },
      // { path: "/profile", element: <Profile /> },
      // { path: "/leaderboard", element: <LeaderBoard /> },
      { path: "*", element: <Navigate to={"/"} /> },
      // { path: "/confirm-user", element: <EmailVerification /> },
      // { path: "/share", element: <Share /> },

      { path: "/", element: <Wrapped /> },
    ],
  },
];

export default routesConfig;
