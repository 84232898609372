import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import WrappedImg from "../../assets/image/wrapped/wrapped.png";
import WrappedMobileImg from "../../assets/image/wrapped/wrapped-mobile.png";
import { Link } from "react-router-dom";

const Wrapped = () => {
  const [sizeSate, setSizeState] = useState(false);
  const handleResize = () => {
    if (window.innerWidth <= 1200) {
      setSizeState(true);
    } else {
      setSizeState(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {sizeSate ? (
        <>
          <div className={styles.second_section}>
            <img src={WrappedMobileImg} />
          </div>
        </>
      ) : (
        ""
      )}
      <div className="container">
        <div className={styles.wrapped_box}>
          <div className={styles.first_section}>
            <h1>Keep the drive alive</h1>
            <p>
              Thank you for joining the fun this season with Bud Light Easy{" "}
              <br />
              Picks. The game isn&apos;t over yet – you&apos;ve still got a shot
              at Super <br /> Bowl tickets.
            </p>
            <p>
              Click below for your chance to win big and be part of the Super{" "}
              <br />
              Bowl excitement!
            </p>
            <a
              href="https://easytoscoreaseat.budlight.com/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <button>Join the action</button>
            </a>
          </div>
          {!sizeSate ? (
            <>
              <div className={styles.second_section}>
                <img src={WrappedImg} />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Wrapped;
