import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "components/common/Footer";
import { refreshToken } from "../Utils/https";
// import MobileFooter from "components/MobileFooter";

const AppViewLayout = () => {
  const navigate = useNavigate();
  const [loginTokenUrl, setLoginTokenUrl] = useState(false);

  const locationa = useLocation();
  const urlParams = new URLSearchParams(locationa.search);
  const loginToken = urlParams.get("login_token");

  const privateRouter = ["/pickem-game", "/profile"];
  const authRouter = ["/reset-password", "/forgot-password", "/"];

  useEffect(() => {
    (async () => {
      const token = await refreshToken();
      if (token) {
        if (authRouter.includes(locationa.pathname)) {
          navigate("/pickem-game");
        }
      } else {
        if (privateRouter.includes(locationa.pathname)) {
          navigate("/");
        }
      }
    })();
  }, [locationa]);

  useEffect(() => {
    if (loginToken) {
      setLoginTokenUrl(false);
    } else {
      setLoginTokenUrl(true);
    }
  }, [loginToken]);

  return (
    <Box>
      {loginTokenUrl && (
        <Box height="75px">
          <Header />
        </Box>
      )}

      <Box
        id="appViewLayout"
        height="calc(100% - 75px)"
        sx={{ overflow: "auto", position: "fixed", width: "100%" }}
      >
        <Outlet />
        <Footer />
      </Box>
      {/* <MobileFooter /> */}
    </Box>
  );
};

export default AppViewLayout;
