import { ThemeProvider } from "@mui/material/styles";

// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import MainView from "./layout/MainView";
import theme from "./theme";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import "components/common/Countdown/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import AgeGate from "components/common/AgeGate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "swiper/css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const App = () => {
  const [isMajor, setIsMajor] = useState(false);

  useEffect(() => {
    checkCookie();
  }, []);

  function checkCookie() {
    let validAge = getCookie("agegate");
    if (validAge === "valid") {
      setIsMajor(true);
    }
  }

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const checkAgeGate = (val) => {
    setIsMajor(val);
  };

  const renderApp = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MainView />
          </ThemeProvider>
        </BrowserRouter>
      </LocalizationProvider>
    );
  };
  const renderAgeGate = () => {
    return <AgeGate isValid={checkAgeGate} />;
  };
  return isMajor ? renderApp() : renderAgeGate();
};

export default App;
