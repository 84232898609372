import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import reportWebVitals from './reportWebVitals';
import './Fonts/Titling Gothic FB Compressed Medium.otf'
import './Fonts/Titling Gothic FB Narrow Standard.otf'
import './Fonts/TitlingGothicFBSkyline-Medium.otf'
import auth from './Utils/auth';

Amplify.configure(auth)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
